import React from "react";
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const SideBar = ({ intl }) => {
  return (
    <div className="col-xl-4 offset-xl-1 col-lg-5 mt-5 mt-lg-0">
      <div className="sidebar-area">
        <div className="widget">
          <h3 className="widget__title"><FormattedMessage id="recent_posts" /></h3>

          <ul className="widget__recent-post list-unstyled mb-0 pb-0">
            <li className="widget__recent-post__single">
              <Link to={'/blog/loyalty-campaigns/'} activeClassName={'link--active'}>
                <h4 className="widget__recent-post__title">
                  <FormattedMessage id="advantages_loyalty_campaigns" />
                </h4>
              </Link>
            </li>
            <li className="widget__recent-post__single">
              <Link to={'/blog/referral-campaigns/'} activeClassName={'link--active'}>
                <h4 className="widget__recent-post__title">
                  <FormattedMessage id="advantages_referral_campaigns" />
                </h4>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(SideBar);
