import React from "react";

import imgC from "../../assets/image/blog/blog-couponise-picture.jpg";

const Article = ({ title, html, ...rest }) => {
  const socialWindow = (url) => {
    const left = (window.screen.width - 570) / 2;
    const top = (window.screen.height - 570) / 2;

    const params = "menubar=no,toolbar=no,status=no,width=570,height=570,top=" + top + ",left=" + left;

    window.open(url,"NewWindow",params);
  }


  const onFacebookClick = () => {
    const pageUrl = encodeURIComponent(document.URL);

    const url = "https://www.facebook.com/sharer.php?u=" + pageUrl;
    socialWindow(url);
  }

  const onTwitterClick = () => {
    const pageUrl = encodeURIComponent(document.URL);
    // const tweet = encodeURIComponent(document.querySelector("meta[property='og:description']").getAttribute("content"));

    const url = "https://twitter.com/intent/tweet?url=" + pageUrl; // + "&text=" + tweet;
    socialWindow(url);
  }

  const onLinkedinClick = () => {
    const pageUrl = encodeURIComponent(document.URL);

    const url = "https://www.linkedin.com/shareArticle?mini=true&url=" + pageUrl;
    socialWindow(url);
  }

  /*function setShareLinks() {
    var pageUrl = encodeURIComponent(document.URL);
    var tweet = encodeURIComponent($("meta[property='og:description']").attr("content"));

    $(".social-share.facebook").on("click", function() {
      url="https://www.facebook.com/sharer.php?u=" + pageUrl;
      socialWindow(url);
    });

    $(".social-share.twitter").on("click", function() {
      url = "https://twitter.com/intent/tweet?url=" + pageUrl + "&text=" + tweet;
      socialWindow(url);
    });

    $(".social-share.linkedin").on("click", function() {
      url = "https://www.linkedin.com/shareArticle?mini=true&url=" + pageUrl;
      socialWindow(url);
    })
  }*/



  return (
    <div className="col-lg-7">
      <div className="blog-title">
        <h1 className="blog-title__heading">{ title }</h1>
        {/*<div className="blog__metainfo">
          <a href="#" className="blog__metainfo__author-name">By David Jones</a>
          <a href="#" className="blog__metainfo__date">Jan 14, 2021</a>
        </div>*/}
      </div>
      <div className="blog-content">
        <div className="blog-content__img">
          <img className="w-100" src={imgC} alt="girl with a loyalty card" />
        </div>
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
        {/*<div className="post-tags-section d-flex align-items-center flex-wrap">
          <h4 className="post-tags-section__title mb-0">Tags:</h4>
          <ul className="post-tags list-unstyled mt-3 mt-lg-5">
            <li><a href="#">Freelance</a></li>
            <li><a href="#">Education</a></li>
            <li><a href="#">Marketing</a></li>
            <li><a href="#">Job</a></li>
            <li><a href="#">Freelance</a></li>
          </ul>
        </div>*/}
        <div className="post-social-share d-flex align-items-center flex-wrap">
          <h4 className="post-social-share__title mb-0">Share:</h4>
          <ul className="social-share list-unstyled mb-0">
            <li><button onClick={() => onLinkedinClick()} role="button" aria-label="share linkedin"><i className="fab fa-linkedin"></i></button></li>
            <li><button onClick={() => onFacebookClick()} role="button" aria-label="share facebook"><i className="fab fa-facebook"></i></button></li>
            <li><button onClick={() => onTwitterClick()} role="button" aria-label="share twitter"><i className="fab fa-twitter"></i></button></li>
          </ul>
        </div>
        {/*<div className="next-prev-btn d-flex align-items-center justify-content-between">
          <div className="prev-btn">
            <a className="btn-link btn-link--prev" href="#"><i className="fa fa-arrow-left"></i>prev</a>
          </div>
          <div className="prev-btn">
            <a className="btn-link btn-link--next" href="#">next<i className="fa fa-arrow-right"></i></a>
          </div>
        </div>*/}
      </div>
    </div>
  );
};

export default Article;
